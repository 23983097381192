<template>
  <div class="main">
    <div class="lgoo" @click="home">返回</div>
    <h3 class="h1_1" v-bind:class="{ 'h1_1_N': h1_1_N } "> 商务合作 &nbsp; &nbsp;Stella@3qlab.com</h3>
    <h3 class="h1_2" v-bind:class="{ 'h1_2_N': h1_2_N } ">技术支持 &nbsp; &nbsp;pgn@3qlab.com</h3>
    <h3 class="h1_3" v-bind:class="{ 'h1_3_N': h1_3_N } ">人员招募 &nbsp; &nbsp;hr@3qlab.com</h3>
    <h3 class="h1_4" v-bind:class="{ 'h1_4_N': h1_4_N }">三驱科技 &nbsp;-<span>驱动世界发展,实现全人类智慧共享</span></h3>
    <div class="h1_5"><a href="http://www.beian.miit.gov.cn">浙ICP备19046295号-2</a> </div>

    <div class="h1_6">&nbsp;Copyright©3QLAB.com 2020<span></span>数据库更新时间：2020年2月5日 </div>
  </div>
</template>

<script>


    export default {
        data() {
            return {
                title: '',
                h1_1_N: false,
                h1_2_N:false,
                h1_3_N:false,
                h1_4_N:false
            }
        },
        mounted() {
           this.btn();
        },
        methods: {
            home(){
                this.$router.go(-1);
            },
            btn() {
                setTimeout(() => {
                    this.h1_1_N = true;
                }, 1000);
                setTimeout(()=>{
                    this.h1_2_N = true;
                },1500);
                setTimeout(()=>{
                    this.h1_3_N = true;
                },2000);
                setTimeout(()=>{
                    this.h1_4_N = true;
                },2300)
            }
        }
    }
</script>

<style scoped>

  .main {
    width: 100%;
    height: 100%;
    background-color: #000000;
    position: relative;
  }
  .main a{
    text-decoration: none;
    color: white;
  }
  .lgoo{
    width: 130px;
    height: 45px;
    position: absolute;
    left: 5%;
    top: 5%;
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
    line-height: 45px;
    color: black;
    background-color: white;
  }

  .main h3 {
    color: #f6f6f6;
    font-family: 仿宋;
    font-size: 23px;
  }

  .h1_1 {
    position: absolute;
    height: 40px;
    left: 50%;
    margin-left: -200px;
    top: 19%;
    opacity: 0;
    transition: 0.6s;
  }

  .h1_1_N {
    opacity: 1;
    top: 20%;
  }

  .h1_2 {
    position: absolute;
    height: 40px;
    left: 50%;
    top: 29%;
    margin-left: -200px;
    opacity: 0;
    transition: 0.4s;
  }
  .h1_2_N {
    opacity: 1;
    top: 30%;
  }
  .h1_3 {
    position: absolute;
    height: 40px;
    left: 50%;
    margin-left: -200px;
    top: 39%;
    opacity: 0;
    transition: 0.4s;
  }
  .h1_3_N {
    opacity: 1;
    top: 40%;
  }
  .h1_4 {
    position: absolute;
    width: 650px;
    height: 40px;
    right: 3%;
    bottom: 5%;
    opacity: 0;
    transition: 0.7s;
  }
  .h1_4_N {
    opacity: 1;
  }
  .h1_4 span {
    margin-left: 30px;
    font-size: 25px;
  }
  .h1_5{
    position: absolute;
    width: 650px;
    height: 40px;
    left: 3%;
    color: white;
    bottom: 5%;
    font-size: 16px;
  }

  .h1_5 a:hover{
    font-weight: bold;
  }
  .h1_6{
    position: absolute;
    width: 650px;
    height: 40px;
    left: 3%;
    color: white;
    bottom: 3%;
    font-size: 14px;
  }
  .h1_6 span{
    width: 2rem;
    display: inline-block;
  }
</style>
